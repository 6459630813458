import React from 'react';


function DefineAFake(props) {
    window.scrollTo(0, 0)
    return (
            <div className='mainPage__container'>
                <div className="leather">
                    <h2 className="leather__title ">
                        Натуральная или искусственная?
                    </h2>
                    <div className="leather__body">
                        <p className="leather__text">
                            Внимательно посмотрите на лицо кожи, её называют "мереей". У каждой шкуры своя природная мерея, отличающаяся фактурой и рисунком. Не бывает двух одинаковых шкур, потому что не бывает двух одинаковых животных. Если кожа натуральная - на ней будут видны небольшие неровности, потертости, укусы насекомых, царапины, ссадины полученные при жизни животного. Это нормально. Хотя здесь все зависит от сортности кожи, чем он выше, тем кожа лучше. Напротив, у искусственной кожи рисунок мереи может быть абсолютно одинаковый, ровный, сделанный как по шаблону. Часто при создании такой кожи применяют плиты для оттиска необходимого рисунка, но опытный кожевник с зорким глазом сумеет найти здесь подвох. Некоторые подумают, что это естественно, но это обманчивая красота.
                        </p>
                        <p className="leather__text">
                            Еще пару слов о фактуре. Особые ценители любят, когда кожа обладает богатой ярко выраженной мереей, с морщинками и складками. На Западе это называется «full grain». Это означает, что лицо кожи не подвергалось никакой обработке и его оставили как есть, в первозданном виде. Но часто можно встретить натуральную кожу, когда лицевую часть зашлифовали, в результате чего она стала гладкая. Это тоже кожа, но с обработанным лицом. Выбирать ее или нет – дело ваше, здесь все зависит вашего вкуса и от самого изделия, которое планируется производить. А также от предпочтений клиента, который будет носить ту или иную вещь, будь то ремень сумка или кошелек.

                        </p>
                        <h3 className='leather__subtitle'>
                            6 способов как отличить натуральную кожу от искусственной
                        </h3>
                        <div className='leather__method'>
                            <h4 className='leather__method_title'>1. Тест на запах</h4>
                            <p className="leather__text">
                                Самый известный и древний способ проверки натуральной кожи – это тест на запах. Если у вас есть кусочек кожи, такой же, как и вся шкура или кусочек от изделия, то следует его слегка поджечь. При поджоге натуральная кожа будет выделять специфический запах, который вы ни с чем не спутаете – это запах паленой кожи. Он терпимый и не резкий. Если вы подожжете искусственную кожу, то при горении она оплавится, выделится резкий и неприятный запах синтетики и пластика.
                                <p>
                                    Однако надо сказать, что этот метод на сегодня уже утратил свою актуальность. Хитрые производители обрабатывают искусственную кожу специальным составом, и при поджоге он имеет запах натуральной кожи. Иными словами, горит она не хуже натуральной.
                                </p>
                                <p>
                                    Если маленького кусочка у вас нет, но вы хотите проверить кожу таким способом, то следует сделать следующее. Возьмите маленькие ножницы для маникюра и очень аккуратно срежьте небольшую полоску 1-2 мм в невидном месте изделия.
                                </p>
                            </p>

                        </div>
                        <div className='leather__method'>
                             <h4 className='leather__method_title'>2. Срез кожи</h4>
                             <p className="leather__text">
                                 Это очень простой способ. Если вы берете изделие или шкуру из натуральной кожи, то обратите внимание на срез кожи (торец). В шкуре это сделать просто, а в готовом изделии ищите место, где пришита молния, а так же на стыке швов. Если срез мягкий, ворсистый, приятный на ощупь и не расслаивается, нитки в нём не торчат, то перед вами, без сомнения, натуральная кожа.
                                 <p>
                                     Если вы видите на срезе торчащие нитки, срез расслаивается или наблюдается пленочное покрытие, то почти на 100% - это искусственная кожа.
                                 </p>
                             </p>

                         </div>
                        <div className='leather__method'>
                             <h4 className='leather__method_title'>3. Тест на изгиб и эластичность</h4>
                             <p className="leather__text">
                                 Самый верный тест проверки натуральной кожи - это тест на изгиб и эластичность. Если сильно согнуть натуральную кожу или надавить на нее пальцем, то останется складка, но кожа вернется в исходное положение даже при сильном сгибе. На ней не будет видно мелких морщинок. А вот на искусственной коже как раз все наоборот. При любом сгибе на искусственной коже останутся микротрещины, а при сильном - вмятины, разрывы, заломы, которые не исчезнут со временем. Это объясняется тем, что натуральная кожа в своем составе имеет природные компоненты, которые позволяют материалу вернуться в исходное положение, после нагрузки на нее. Искусственная кожа таких компонентов не имеет. Изделия из искусственной кожи недолговечны, спустя время материал рвется, в отличие от натуральной кожи, которая через 5-10 лет, несмотря на потертости, не теряет своей привлекательности.
                             </p>

                         </div>
                        <div className='leather__method'>
                             <h4 className='leather__method_title'>4. Тест на теплопроводность</h4>
                             <p className="leather__text">
                                 Натуральная кожа – это отличный проводник тепла. Если взять ее в руку и подержать несколько секунд, то можно почувствовать, что материал нагреется, станет немного теплее. У искусственной кожи теплопроводных свойств нету, и если ее долго держать в руке, то она останется прохладной.
                                 <p>
                                     Более того, после отнятия ладони на поверхности материала будет запотевшее пятно.
                                 </p>
                             </p>

                         </div>
                        <div className='leather__method'>
                             <h4 className='leather__method_title'>5. Качество краски</h4>
                             <p className="leather__text">
                                 Это скорее не тест, а рекомендация по проверке качества краски, в которую окрашена кожа. Если провести слегка влажной салфеткой или платком по лицевой стороне кожи, то на салфетке не должно остаться следов краски. Это нужно сделать как со стороны мереи, так и со стороны бахтармы (обратной стороны кожи). Более того, вся поверхность кожи должна быть прокрашена равномерно, без пятен и подтеков.
                                 <p>
                                     Не мочите салфетку, не забывайте, что кожа не любит воду.
                                 </p>
                             </p>

                         </div>
                        <div className='leather__method'>
                             <h4 className='leather__method_title'>6. Тест на влагу</h4>
                             <p className="leather__text">
                                 Если слегка капнуть на кожу обычной воды и немного подождать, натуральная кожа впитает воду в себя. Исключение составляют кожи, покрытые лаком, который навсегда закрывает поры кожи и не дает влаге впитаться. С таких кож вода стечет.
                                 <p>
                                     С искусственной кожи вода всегда соскользнет и не впитается.
                                 </p>
                             </p>
                         </div>
                        <p className="leather__text">
                            Мы очень надеемся, что наши советы как отличить натуральную кожу от искусственной помогут вам выбирать только качественный продукт. <br/>
                            С уважением к вам.
                        </p>
                        <p className="leather__footer">
                             Massimo Olensky.
                        </p>
                    </div>
                </div>
            </div>
    );
}

export default DefineAFake;