import React from 'react';


function CareOfProducts(props) {
    window.scrollTo(0, 0)
    return (
            <div className='mainPage__container'>
                <div className="care">
                    <h2 className="care__title ">
                        Какой уход необходим изделиям из кожи?
                    </h2>
                    <div className="care__body">
                        <p className="care__text">
                            Натуральная кожа - один из самых древних природных материалов, которые мы научились обрабатывать и использовать в производстве разных полезных в обиходе предметов: обуви, сумок, кошельков, ремней, перчаток и многого другого. Гибкость, прочность, удобство в работе, красота - вот свойства, которые делают кожу такой популярной. И несмотря на то что ученые давно изобрели заменитель кожи, с натуральным сырьем он сравниться не может, и качественные стильные аксессуары чаще всего делают из настоящей кожи. Данный материал требует определенного внимания - и сейчас мы дадим вам несколько советов, как ухаживать за кожаными изделиями.
                        </p>
                        <h3 className='care__subtitle'>
                            Несколько общих рекомендаций:
                        </h3>
                        <ul className='generalRecomendation'>
                            <li className="generalRecomendation__item">
                                • Если изделие намокло - попало под дождь - не сушите его у нагревательных приборов, батареи или при помощи фена - дайте ему высохнуть естественным образом при комнатной температуре.
                            </li>
                            <li className="generalRecomendation__item">
                                • Не оставляете изделие надолго под солнцем - поверхность неизбежно будет выгорать.
                            </li>
                            <li className="generalRecomendation__item">
                                • Храните кожаные сумки в тканых, пропускающих воздух мешках, с которыми изделия обычно продаются - ни в коем случае не храните изделия в полиэтиленовом пакете!
                            </li>
                            <li className="generalRecomendation__item">
                                • Если кожа загрязнилась, постарайтесь использовать во время чистки как можно меньше воды, мыла и других очищающих средств. Если можно обойтись без них, просто используйте сухую тряпочку.
                            </li>
                            <li className="generalRecomendation__item">
                                • Удалять пятна нужно как можно быстрее, пока грязь не проникла глубоко в поры кожи.
                            </li>
                            <li className="generalRecomendation__item">
                                • Не нагружайте чрезмерно кожаные сумки и кошельки - кожа растягивается и теряет форму, особенно если она влажная: восстановить форму часто бывает очень сложно, а порой невозможно.
                            </li>
                            <li className="generalRecomendation__item">
                                • Острые и угловатые твердые предметы могут повредить вашу сумку. Будьте внимательны - используйте футляры, чехлы, ключницы и прочее.
                            </li>
                            <li className="generalRecomendation__item">
                                • Несмотря на то, что натуральная кожа в процессе производства пропитывается всевозможными жирами, воском и т.д., время от времени ей все же нужно питание. Если вы заметили, что поверхность начинает подсыхать, нанесите специальный крем на основе пчелиного воска или обычный косметический крем для лица или рук.
                            </li>
                        </ul>
                        <p className="care__text">
                            В процессе использования кожа постепенно будет протираться, обиваться и на ней будут появляться царапины. Есть два пути: восстанавливать первоначальный вид, то есть поддерживать вещь в состоянии новой, или дать ей естественно стареть, что часто бывает предпочтительным вариантом, так как некоторые сумки, кошельки и ремни могут стареть очень красиво. Мелкие следы использования будут только придавать таким вещам неповторимый шарм и наполнять жизнью.
                        </p>
                        <p className="care__text">
                            Второй вариант предпочтителен не только для повседневных аксессуаров, но чаще, подходит, если вам нравится винтажный вид изделия. Если вы выбрали второй вариант, вам остается только время от времени питать кожу качественными кремами.
                        </p>
                        <p className="care__text">
                            Если вам ближе первый путь и для вас важен идеальный вид аксессуара, то по мере появления дефектов на поверхности вам нужно будет обзавестись некоторыми средствами для избавления от царапин и потёртостей. Первое средство, которое порекомендовал бы наш Модный Дом, это жидкая кожа французской фирмы Saphir - Creme Renovatrice. Его следует использовать, если вы планируете устранить недостатки верхнего слоя кожи, то есть локальные потертости или царапины (в том числе глубокие). Данное средство не подходит для перекраски изделия или ремонта разрывов кожи.
                        </p>
                        <p className="care__text">
                            Для использования нанесите на губку или палец немного средства и затрите поврежденный участок. Подождите, пока средство подсохнет, это займёт около 15-20 минут. Потом разотрите тряпочкой или салфеткой. Если на изделии осталась шероховатость, излишки жидкой кожи можно осторожно снять с помощью очень мелкой наждачной бумаги. Чтобы скрыть границы между реставрируемым местом и остальной поверхностью кожи, воспользуйтесь подкрашивающим кремом.
                        </p>
                        <p className="care__text">
                            Стоит сказать отдельно об анилиновой коже. Этот тип кожи не имеет практически никакого защитного покрытия. Такая кожа красива, на ней заметна вся естественная структура. В то же время она очень деликатна и требует особого к себе внимания.
                        </p>
                        <p className="care__text">
                            А теперь как определить, из какого вида кожи сделано ваше изделие? Капните на поверхность обычной водой: если вода быстро впитывается, глубоко проникая в кожу и оставляя темное пятно, это анилиновая кожа.
                            Для подкраски или полной перекраски изделий из анилиновой кожи необходим краситель проникающего действия.
                            Если капля воды не впитывается, а остается на поверхности, значит кожа имеет покрытие. В этом случае используйте краски или жидкие кожи поверхностного типа. Конечно, если вы не хотите рисковать, чтобы не испортить любимую вещь, и не готовы заниматься восстановлением и тем более ремонтом, всегда есть возможность довериться профессионалам и отнести ваше изделие в мастерскую.
                            Как видите, ухаживать за изделиями из кожи не так сложно. Пожалуйста, не пренебрегайте данными советами при покупке сумок нашего бренда - и они прослужат вам долго.
                            С уважением к вам.
                        </p>
                        <p className="care__footer">
                            Massimo Olensky.
                        </p>
                    </div>
                </div>
            </div>
    );
}

export default CareOfProducts;