import React from 'react';

function DeliveryAndReturn(props) {
    window.scrollTo(0, 0)
    return (
            <div className='mainPage__container'>
                <div className='delivery'>
                    <div className='delivery__title'>
                        Обратите внимание:
                    </div>
                    <p className='delivery__text'>
                        Наши изделия изготавливаются по индивидуальному заказу, сроки исполнения самого изделия 10-14 дней с момента поступления оплаты.
                    </p>
                    <div className='delivery__title'>
                        Доставка:
                    </div>
                    <div className='delivery__subtitle'>
                        Доставка наших изделий осуществляется:
                    </div>
                    <div className='delivery__subtitle'>
                        БЕЛПОЧТОЙ ПО Республике Беларусь.
                    </div>
                    <p className='delivery__text'>
                        • Доставка согласно тарифов РУП БелПочта, от 15 рублей
                    </p>
                    <p className='delivery__text'>
                        • Без примерки.
                    </p>
                    <p className='delivery__text'>
                        • От 3 до 5 дней
                    </p>
                    <p className='delivery__text'>
                        • Доставка только после оплаты
                    </p>
                    <p className='delivery__text'>
                        • Возможность оплаты банковской картой на сайте
                    </p>
                    <div className='delivery__subtitle'>
                        СДЭК ИЛИ DPD ПО СТРАНАМ СНГ.
                    </div>
                    <p className='delivery__text'>
                        • Доставка согласно тарифов СДЭК или DPD, от 30 рублей
                    </p>
                    <p className='delivery__text'>
                        • Без примерки.
                    </p>
                    <p className='delivery__text'>
                        • От 7 до 15 дней
                    </p>
                    <p className='delivery__text'>
                        • Доставка только после оплаты
                    </p>
                    <p className='delivery__text'>
                        • Возможность оплаты банковской картой на сайте
                    </p>
                </div>
                <div className='return'>
                    <div className='return__title'>
                        Возврат
                    </div>
                    <p className='return__text'>
                        Изделия с дефектами, вызванными нарушением правил по уходу и эксплуатации, возврату не подлежат, и гарантия на них не распространяется.
                    </p>
                    <p className='return__text'>
                        <b>Гарантийный период</b> -
                        это срок, во время которого клиент, обнаружив недостаток товара имеет право потребовать от продавца или изготовителя принять меры по устранению дефекта. Продавец должен устранить недостатки, если не будет доказано, что они возникли вследствие нарушений покупателем правил эксплуатации.
                    </p>
                    <div className='return__subtitle'>
                        Обслуживание по гарантии включает в себя:
                    </div>
                    <p className='return__text'>
                        устранение недостатков товара в сертифицированных сервисных центрах;
                    </p>
                    <p className='return__text'>
                        обмен на аналогичный товар без доплаты (при предъявлении оригинала чека);
                    </p>
                    <p className='return__text'>
                        обмен на похожий товар с доплатой (при предъявлении оригинала чека);
                    </p>
                    <p className='return__text'>
                        возврат товара и перечисление денежных средств на счёт покупателя (при предъявлении оригинала чека).
                    </p>
                    <p className='return__text'>
                        Согласно ГОСТ 28631-2005, гарантийный срок составляет:
                    </p>
                    <p className='return__text'>
                        на женские, мужские молодежные, дорожные, спортивные, рюкзаки женские, мужские, молодежные, дорожные сумки - 50 дней с даты продажи товара;
                    </p>
                    <p className='return__text'>
                        Согласно статье 28 Закона "О защите прав потребителей" потребитель имеет право на обмен и возврат товара надлежащего качества
                    </p>
                    <p className='return__text'>
                        Потребитель вправе в течение четырнадцати дней с момента передачи ему непродовольственного товара, если более длительный срок не объявлен продавцом, в месте приобретения или иных местах, объявленных продавцом, возвратить товар надлежащего качества или обменять его на аналогичный товар других размера, формы, габарита, фасона, расцветки или комплектации, произведя в случае разницы в цене необходимый перерасчет с продавцом.
                    </p>
                    <p className='return__text'>
                        Требование потребителя об обмене либо возврате товара подлежит удовлетворению, если товар не был в употреблении, сохранены его потребительские свойства и имеются доказательства приобретения его у данного продавца.
                    </p>
                    <div className='return__subtitle'>
                        Условия возврата денежных средств
                    </div>
                    <p className='return__text'>
                        Возврат денежных средств осуществляется только при предъявлении оригинала фискального документа, подтверждающего оплату (чека).
                    </p>
                    <p className='return__text'>
                        При доставке по Беларуси документом, дающим право на возврат денежных средств, является чек с почты.
                    </p>
                    <p className='return__text'>
                        Если товар был оплачен банковской картой через сайт, то возврат осуществляется на карту, с которой была произведена оплата. Срок поступления денежных средств на карту - от 3 до 30 дней с момента осуществления возврата Продавцом.
                    </p>
                    <div className='return__subtitle'>
                        Безналичный расчет. Для перечисления денежных средств необходима следующая информация:
                    </div>
                    <p className='return__text'>
                        • Код отделения банка, где открыт счет
                    </p>
                    <p className='return__text'>
                        • Расчетный счет банка для перечисления физ. лицам
                    </p>
                    <p className='return__text'>
                        • УНП банка
                    </p>
                    <p className='return__text'>
                        • Лицевой счет Клиента
                    </p>
                    <p className='return__text'>
                        • ФИО владельца счета
                    </p>
                    <div className='return__subtitle'>
                        КАК ПРАВИЛЬНО ОФОРМИТЬ ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ?
                    </div>
                    <p className='return__text'>
                        Срок перечисления денежных средств осуществляется в срок до 10 календарных дней. Срок зачисления денежных средств на расчетный счет Клиента зависит от внутреннего регламента банка-получателя. Возврат денежных средств будет осуществлен на банковскую платежную карточку в течение 7 (семи) календарных дней со дня получения обращения. Сумма возврата будет равняться сумме покупки. Возврат осуществляется на ту же карточку, с которой осуществлялась оплата.
                    </p>
                    <div className='return__subtitle'>
                        Оплата через Систему «Расчёт» (ЕРИП)
                    </div>
                    <p className='return__text'>
                        • Пункт “Система “Расчет” (ЕРИП)
                    </p>
                    <p className='return__text'>
                        • Интернет-магазины/сервисы
                    </p>
                    <p className='return__text'>
                        • A-Z Латинские домены
                    </p>
                    <p className='return__text'>
                        • M
                    </p>
                    <p className='return__text'>
                        • MaraudeurDeNuit.com
                    </p>
                    <p className='return__text top-indent'>
                        1.	Для оплаты ввести Номер заказа
                    </p>
                    <p className='return__text'>
                        2.	Проверить корректность информации
                    </p>
                    <p className='return__text'>
                        3.	Совершить платеж.
                    </p>
                    <div className='return__subtitle'>
                        Оплата путем Онлайн Платежа
                    </div>
                    <p className='return__text'>
                        Для проведения оплаты с использованием банковской платежной карточки в режиме онлайн необходимо выполнить следующие действия:
                    </p>
                    <p className='return__text'>
                        • сформировать заказ
                    </p>
                    <p className='return__text'>
                        • выбрать пункт «Оплата с использованием банковской карточки»
                    </p>
                    <p className='return__text'>
                        • после переадресации на защищенную страницу банка ввести номер карточки, срок ее действия, имя владельца карты и уникальный трехзначный код
                    </p>
                    <p className='return__text'>
                        • нажать кнопку «Оплатить»
                    </p>
                    <p className='return__text'>
                        • в случае успешного завершения операции Вы увидите соответствующее уведомление
                    </p>
                    <p className='return__text top-indent'>
                        К оплате принимаются карты международных платежных систем VISA, MasterCard, платежной системы БЕЛКАРТ. Оплату также можно совершить посредством сервисов Apple Pay, Samsung Pay.
                    </p>
                    <p className='return__text'>
                        Безопасность совершения платежа обеспечивается современными методами проверки, шифрования и передачи данных по закрытым каналам связи.
                    </p>
                    <p className='return__text'>
                        Ввод данных карточки осуществляется на защищенной авторизационной странице банка. Для оплаты необходимо ввести реквизиты карточки: номер, имя держателя, срок действия и трехзначный код безопасности. Трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard) — это три цифры, находящиеся на обратной стороне карточки. Если карточка поддерживает технологию 3DSecure или Интернет-пароль для держателей карточек БЕЛКАРТ, Вы будете перенаправлены на страницу банка, выпустившего карточку, для ввода кода безопасности.
                    </p>
                    <p className='return__text'>
                        При оплате с помощью Apple Pay выберете карту из приложения Wallet, воспользуйтесь код-паролем или иным способом аутентификации, в зависимости от того, какой способ выбран в приложении.
                    </p>
                    <p className='return__text'>
                        При оформлении заказа с помощью Samsung Pay нажмите «Оплатить Samsung Pay», введите ваш Samsung Account и подтвердите покупку на вашем смартфоне (по отпечатку пальца, радужке или PIN-коду Samsung Pay).
                    </p>
                    <p className='return__text'>
                        Предоставляемая Вами персональная информация (например: имя, адрес, телефон, e-mail, номер банковской карты и прочее) является конфиденциальной и не подлежит разглашению. Данные карточки передаются только в зашифрованном виде и не сохраняются на данном интернет-ресурсе.
                    </p>
                    <p className='return__text top-indent'>
                        На основании заключенных договоров, [ООО/ИП/ЧУП/Организация] поручает обработку персональных данных клиентов [ООО/ИП/ЧУП/Организация], осуществляющих покупку товаров/работ/услуг на сайте [www…..by]) следующим уполномоченным лицам (далее - УЛ):
                    </p>
                    <table className='delivery__table'>
                        <tbody>
                        <tr>
                            <td>Наименование УЛ, УНП</td>
                            <td>Место нахождения УЛ</td>
                            <td>Цель обработки персональных данных</td>
                            <td>Перечень обрабатываемых персональных данных</td>
                            <td>Перечень действий УЛ по обработке персональных данных</td>
                            <td>Срок обработки</td>
                        </tr>
                        <tr>
                            <td>ООО «ИКомЧардж» УНП 191670289</td>
                            <td>Республика Беларусь, г. Минск, пр. Дзержинского, 104, офис 1801</td>
                            <td>Обеспечение технической возможности проведения платежей с использованием банковских пластиковых карточек и иных платежных методов *</td>
                            <td>Фамилия и имя держателя карточки, номер банковской карточки, CVC/CVV, адрес электронной почты, ID-заказа</td>
                            <td>сбор, систематизация, хранение, использование, предоставление, удаление персональных данных</td>
                            <td>5 лет с момента осуществления платежа</td>
                        </tr>
                        </tbody>
                    </table>
                    <p className='return__text top-indent'>
                        Вставить, если подключаете ЕРИП*ООО «ИКомЧардж» при указании номера телефона при формировании оплаты через АИС «Расчет» направляет регистрационное SMS-сообщения о том, что в АИС «Расчет» сформировано требование на оплату
                    </p>
                    <p className='return__text'>
                        Вставить, если подключаете оплату по картам*ООО «ИКомЧардж» после проведения оплаты направляет карт-чек на e-mail, указанный при оплате
                    </p>
                </div>
            </div>
    );
}

export default DeliveryAndReturn;